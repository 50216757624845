define([], function() {

  const classNamePrefix = 'moreInfoOnHover';
  const classNames = {
    container: `${classNamePrefix}_container`,
    text: `${classNamePrefix}_text`,
    textShow: `${classNamePrefix}_text-show`
  };

  class activatePopUp {

    init(element) {
      const modifiersString = element.getAttribute('data-more-info-on-hover-target-modifiers');
      if (!modifiersString) {
        return;
      }
      const modifiers = modifiersString.split(',');
      modifiers.forEach(modifier => {
        const container = element.querySelector(`.${classNames.container}-${modifier}`);
        const text = element.querySelector(`.${classNames.text}-${modifier}`);
        const close = element.querySelector(`.${classNames.text}_close-${modifier}`);

        if (container && text) {
          container.addEventListener('mouseover', () => {
            text.classList.add(classNames.textShow);
          });
          close.addEventListener('click', () => {
            text.classList.remove(classNames.textShow);
          });
        }
      });
    }
  }

  return activatePopUp;
});
